/*global jarallax*/

import React from 'react'

const ParallaxBannerVideo = props => {
  jarallax(document.querySelectorAll('.jarallax'), props.options)

  return (
    <div className={`jarallax parallax-banner-video with-arrow-down ${props.className}`} data-jarallax-video={props.video}>
      {props.children}
    </div>
  )
}

export default ParallaxBannerVideo
