import React, { Component } from 'react';
import Header from './Header';
import HeaderFixed from './HeaderFixed';
import ParallaxBannerVideo from './ParallaxBannerVideo';
import NavSmall from '../components/NavSmall';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

import videoContact from '../assets/videos/contact.mp4';

class Contact extends Component {
  componentWillMount () {
    this.props.handleCurrentPage(this.props.location.pathname)
  }

  render () {
    return (
      <div>
        <NavSmall menuIsOpen={this.props.menuIsOpen} toogleMenuSmall={this.props.toogleMenuSmall} />
        <Header currentPage={this.props.currentPage} toogleMenuSmall={this.props.toogleMenuSmall} className='contact' />
        <HeaderFixed
          className='contact'
          currentPage={this.props.currentPage}
          toogleMenuSmall={this.props.toogleMenuSmall}
        />
        <div className='main contact'>
          <ParallaxBannerVideo
            className='mb-50px'
            video={`mp4:${videoContact}`}
            options={{
              // imgSrc: './assets/videos/contactBg.jpg'
            }}
          >
            <h2 className='tg--text-2'>Contact</h2>
          </ParallaxBannerVideo>
        </div>
        <ContactForm title='contact us' subtitle='To learn more about RioSlum Studio, our services, or explore ways to work together, contact us below to spark a conversation.' />
        <Footer page={this.props.currentPage} />
      </div>
    )
  }
}

export default Contact
