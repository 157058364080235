import React from 'react'
import { Link } from 'react-router-dom'

import burgerMenu from '../assets/menu_btn.svg';

const Nav = props => {
  return (
    <div>
      <figure className='toogle-nav'>
        <img src={burgerMenu} alt='' onClick={props.toogleMenuSmall} />
      </figure>
      <ul className='header-nav'>
        <li>
          {/* <Link className={ (props.currentPage === 'work') ? 'active' : '' } to="/" >Work</Link> */}
          <Link className={(props.workActive) ? 'active' : ''} to='/' >Work</Link>
        </li>
        <li>
          <Link className={(props.currentPage === 'about') ? 'active' : ''} to='/about' >About</Link>
        </li>
        <li>
          <Link className={(props.currentPage === 'services') ? 'active' : ''} to='/services'>Services</Link>
        </li>
        {/* <li>
          <Link className={(props.currentPage === 'startups') ? 'active' : ''} to='/startups'>Startups</Link>
        </li> */}
        <li>
          <Link className={(props.currentPage === 'contact') ? 'active' : ''} to='/contact'>Contact</Link>
        </li>
      </ul>
    </div>
  )
}

export default Nav
