/*global google*/

import React, { Component } from "react";
import Header from "./Header";
import HeaderFixed from "./HeaderFixed";
import SubMenu from "./SubMenu";
import ParallaxBannerVideo from "./ParallaxBannerVideo";
import ParallaxBanner from "./ParallaxBanner";
import mapStyle from "../assets/map.json";
import NavSmall from "../components/NavSmall";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Config from "../config";

import parallaxAbout from "../assets/images/parallax/parallax_about.jpg";
import parallaxAboutVideo from "../assets/videos/about.mp4";
import videoAbout from "../assets/videos/placeholder.mp4";

import teamJesse from "../assets/images/team/jesse.jpg";
import teamPedro from "../assets/images/team/pedro.jpg";
import teamPatricia from "../assets/images/team/patricia.jpg";
import teamRogerio from "../assets/images/team/rogerio.jpg";
import teamLeandro from "../assets/images/team/leandro.jpg";

import teamLeonardo from "../assets/images/team/leonardo.jpg";
import teamElle from "../assets/images/team/elle.jpg";

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 39.952584,
      lng: -75.165222,
    };

    this.map = this.map.bind(this);
  }

  componentWillMount() {
    this.props.handleCurrentPage(this.props.location.pathname);
  }

  componentDidMount() {
    this.map();
  }

  map() {
    const latLon = { lat: this.state.lat, lng: this.state.lng };
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 10,
      center: latLon,
      styles: mapStyle,
      disableDefaultUI: true,
      scrollwheel: false,
      draggable: false,
    });
    new google.maps.Marker({
      position: latLon,
      map: map,
    });
  }

  handleLatLon(city) {
    const locations = {
      philadelphia: {
        lat: 39.9495016,
        lng: -75.1702704,
      },
      washington: {
        lat: 38.8769031,
        lng: -77.0084644,
      },
      saopaulo: {
        lat: -23.6069107,
        lng: -46.6635039,
      },
      riodejaneiro: {
        lat: -23.0241139,
        lng: -43.5151053,
      },
      guatemala: {
        lat: 14.634915,
        lng: -90.506882,
      },
      argentina: {
        lat: -34.592715,
        lng: -58.425934,
      },
    };

    this.setState({
      lat: locations[city].lat,
      lng: locations[city].lng,
    });

    setTimeout(() => {
      this.map();
    }, 100);
  }

  render() {
    return (
      <div>
        <NavSmall
          menuIsOpen={this.props.menuIsOpen}
          toogleMenuSmall={this.props.toogleMenuSmall}
        />
        <Header
          currentPage={this.props.currentPage}
          toogleMenuSmall={this.props.toogleMenuSmall}
          className="about"
        >
          <SubMenu links={Config.components.about.links} />
        </Header>
        <HeaderFixed
          className="about"
          currentPage={this.props.currentPage}
          toogleSubMenu={this.props.toogleSubMenu}
          toogleMenuSmall={this.props.toogleMenuSmall}
        >
          <SubMenu
            links={Config.components.about.links}
            showSubMenu={this.props.showSubMenu}
          />
        </HeaderFixed>
        <div className="main about">
          <ParallaxBannerVideo
            className="mb-50px"
            video={`mp4:${parallaxAboutVideo}`}
            options={
              {
                // imgSrc: './assets/videos/aboutBg.jpg'
              }
            }
          >
            <h2 className="tg--text-2">About</h2>
          </ParallaxBannerVideo>

          <div id="about-rio" className="intro">
            <div className="container">
              <h2 className="title">About Rio</h2>
              <div className="row">
                <div className="col-xs-12 col-lg-6">
                  <p className="text">
                    RioSlum Studio offers branding, design, messaging, strategy,
                    and videography services to daring companies across the
                    globe. It’s our mission to turn your vision into something
                    tangible, beautiful, and relatable.
                  </p>
                  <p className="text why">
                    <strong>Why ‘RioSlum’</strong> Rio*Slum (rē′ō sləm):
                  </p>
                  <p className="text">
                    <strong className="italic">Adjective</strong>
                  </p>
                  <ul className="decimal">
                    <li>
                      a term that encapsulates the dichotomy between beauty and
                      grit, affluence and inequality, stunning beaches and
                      visible slums in the city of Rio de Janeiro.
                    </li>
                    <li>
                      a term that references the complex journey of the
                      entrepreneur both its creativity and energy, struggle and
                      disappointment.
                    </li>
                  </ul>
                  <p className="text">
                    <strong className="italic">Noun</strong>
                  </p>
                  <ul className="decimal">
                    <li>
                      a place that’s brilliant street art and enigmatic style
                      inspires RioSlum Studio’s Brazilian Art Director (Pedro
                      Jovelli), the Olympic opening ceremonies, pop music
                      videos, and countless soft drink commercials.
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-lg-6">
                  <video controls className="video">
                    <source src={videoAbout} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div id="team" className="team">
            <div className="container">
              <h5 className="title">Team</h5>
              <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="member">
                    <figure className="image">
                      <img src={teamJesse} alt="" />
                    </figure>
                    <p className="name">Jesse Grainger</p>
                    <p className="role">Chief Executive Officer</p>
                    <p className="description">
                      Jesse brings a wealth of experience working in marketing,
                      communications, and entre-preneurship to the RioSlum
                      Studio team. Jesse has worked as a political reporter,
                      photographer, as a digital media specialist for musicians,
                      and as a marketing specialist for an investment firm in
                      East Africa. Most recently, he served as Director of
                      Business Development at Agora Partnerships where he
                      oversaw communications, fundraising, partnerships, and
                      strategy. During his tenure, Agora Partnerships tripled
                      its annual revenue and opened three new field offices. As
                      CEO of RioSlum Studio, Jesse focuses on new business
                      development and directing branding strategy for clients.
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="member">
                    <figure className="image">
                      <img src={teamPedro} alt="" />
                    </figure>
                    <p className="name">pedro jovelli</p>
                    <p className="role">Art Director</p>
                    <p className="description">
                      Pedro has accumulated over 15 years of graphic design
                      experience working in his home country of Brazil, as well
                      as in the United States. He believes a strong visual
                      identity and bold, dramatic designs are critical elements
                      to helping social enterprises reach mainstream, global
                      audiences. Over the last decade, Pedro has worked as a
                      graphic designer, interactive website developer, front-end
                      designer, and art director. For nearly five years, Pedro
                      worked as the Art Director of Agora Partnerships building
                      daring, memorable brands for both the non-profit and the
                      portfolio companies in its Accelerator. At RioSlum, Pedro
                      leads design and art direction.
                    </p>
                  </div>
                </div> 
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="member">
                    <figure className="image">
                      <img src={teamElle} alt="" />
                    </figure>
                    <p className="name">Elle McPherson</p>
                    <p className="role">CFO</p>
                    <p className="description">
                      Elle acts as RioSlum’s CFO via Zest Solutions, her
                      financial management and coaching firm. Elle’s has worked
                      as a finance and operations manager for several nonprofits
                      in Washington, DC and beyond. Elle holds a MBA/MA in
                      Nonprofit Management and Sustainable International
                      Development from the Heller School of Social Policy &
                      Management at Brandeis University and a BA from Bates
                      College. Elle is an Associate Certified Coach (ACC) with
                      the International Coach Federation and received her
                      professional coach certification through the Institute for
                      Professional Excellence in Coaching (iPEC).
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="member">
                    <figure className="image">
                      <img src={teamRogerio} alt="" />
                    </figure>
                    <p className="name">Rogerio Banquieri</p>
                    <p className="role">Head of Web Development</p>
                    <p className="description">
                      Rogerio is a seasoned IT professional with over 10 years
                      of experience in software engineering, five of which were
                      spent working for a Brazilian free-to-air television
                      network called Globo TV. Globo is the largest commercial
                      TV network in South America. Rogerio works in JavaScript
                      (React, Node Js), html5, and css3. He is a lover of open
                      sources and passionate about taking projects from concept
                      to final product.
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="member">
                    <figure className="image">
                      <img src={teamPatricia} alt="" />
                    </figure>
                    <p className="name">patricia torres</p>
                    <p className="role">Graphic Designer & 3D Animator</p>
                    <p className="description">
                      Patricia is a 3D artist with over a decade of experience.
                      Patricia skilled in developing concepts, 2D and 3D
                      animations, compositions, and illustrations. She has
                      extensive experience working on TV commercials and
                      corporate videos. Patricia has developed projects for
                      major brands, advertising agencies, and feature films.
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="member">
                    <figure className="image">
                      <img src={teamLeonardo} alt="" />
                    </figure>
                    <p className="name">Leonardo Marconi</p>
                    <p className="role">Account Manager</p>
                    <p className="description">
                      Leonardo (“Leo”) is an Account Manager with a decade of
                      inter-national experience in the advertising and marketing
                      field. Prior to joining Rio, Leo was awarded an ABC
                      Culture Trophee for account services as part of Omnicom’s
                      Global Awards. At RioSlum, Leo is responsible for managing
                      client relationships, Latin American business development,
                      and project management.
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="member">
                    <figure className="image">
                      <img src={teamLeandro} alt="" />
                    </figure>
                    <p className="name">Leandro Costa</p>
                    <p className="role">Senior Full-stack Web Engineer</p>
                    <p className="description">
                      Leandro is an experienced Full-stack Web Developer who has
                      worked with an array of programming languages throughout
                      his career including PHP, JavaScript, and Python. Besides
                      development, he is passionate about UX and digital
                      customer experience and always seeks to learn new and
                      innovative ways of bringing complex digital interfaces to
                      life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ParallaxBanner
            className="parallax about-1 mb-20px"
            image={parallaxAbout}
            options={{
              speed: 0.2,
              automaticResize: true,
            }}
          />

          <div id="staff-locations" className="offices">
            <div className="container">
              <h2 className="title">Staff Locations</h2>
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <p className="text">
                    {" "}
                    RioSlum Studio is officially incorporated in Philadelphia,
                    PA. We also have staff presence in Brazil, Guatemala, and
                    Argentina.
                  </p>

                  <ul>
                    <li
                      className="office"
                      onClick={() => {
                        this.handleLatLon("philadelphia");
                      }}
                    >
                      Philadelphia
                    </li>
                    <li
                      className="office"
                      onClick={() => {
                        this.handleLatLon("saopaulo");
                      }}
                    >
                      São Paulo, Brazil
                    </li>
                    <li
                      className="office"
                      onClick={() => {
                        this.handleLatLon("riodejaneiro");
                      }}
                    >
                      Rio de Janeiro, Brazil
                    </li>
                    <li
                      className="office"
                      onClick={() => {
                        this.handleLatLon("guatemala");
                      }}
                    >
                      Guatemala City, Guatemala
                    </li>
                    <li
                      className="office"
                      onClick={() => {
                        this.handleLatLon("argentina");
                      }}
                    >
                      Buenos Aires, Argentina
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div id="map" className="map" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm
          title="contact us"
          subtitle="Spark a conversation with RioSlum Studio."
        />
        <Footer page={this.props.currentPage} />
      </div>
    );
  }
}

export default About;
