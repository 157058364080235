import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import App from './container/App';
import './scss/style.scss';

const Application = (
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>
);

render(Application, document.getElementById('app'));
