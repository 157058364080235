import React, { Component } from 'react';
import Nav from './Nav';
import { Link } from 'react-router-dom';

import logo from '../assets/header_brand.svg';

class HeaderFixed extends Component {
  constructor (props) {
    super(props)

    this.state = {
      opacity: 0,
      top: '-100%',
      workActive: false
    }

    this.onScrollHandler = this.onScrollHandler.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.onScrollHandler, false)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScrollHandler, false)
  }

  onScrollHandler () {
    const headerFixed = document.querySelector('.header-fixed')
    const currentPage = this.props.currentPage
    const pageYOffset = window.pageYOffset
    const innerHeight = window.innerHeight
    let opacity = 0
    let top = '-100%'
    let workActive = false
    const portfolio = document.getElementById('portfolio')

    if (currentPage === 'work') {
      if (pageYOffset > innerHeight) {
        opacity = 1
        top = 0
      }

      if (pageYOffset > innerHeight + portfolio.offsetHeight) {
        workActive = false
      } else {
        workActive = true
      }
    } else {
      if (pageYOffset > headerFixed.offsetHeight) {
        opacity = 1
        top = 0
      }
    }

    this.setState({
      opacity: opacity,
      top: top,
      workActive: workActive
    })
  }

  render () {
    return (
      <header className='header-fixed' style={{ opacity: this.state.opacity, top: this.state.top }}>
        <div className='main-menu'>
          <div className='content'>
            <figure className='logo'>
              <Link to='/' ><img src={logo} alt='' /></Link>
            </figure>
            <Nav currentPage={this.props.currentPage} toogleMenuSmall={this.props.toogleMenuSmall} workActive={this.state.workActive} />
          </div>
        </div>
        {this.props.children}
      </header>
    )
  }
}

export default HeaderFixed
