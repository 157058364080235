import React from 'react'
import { Link } from 'react-router-dom'

const Portfolio = () => (
  <div id='portfolio' className='portfolio'>
    <h5 className='title'>Work</h5>
    <div className='portfolio-items clearfix'>
      <div className='col-12'>
        <div id='power-to-grow' className='portfolio-item h400' data-item='1'>
          <div className='mask'>
            <Link to='/portfolio/power-to-grow' >
              <h5>Power to Grow</h5>
              <p>View work</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div id='pay-by-court' className='portfolio-item h200' data-item='2'>
          <div className='mask'>
            <Link to='/portfolio/pay-by-court' >
              <h5>playbycourt</h5>
              <p>View work</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div id='maven-women' className='portfolio-item h200' data-item='3'>
          <div className='mask'>
            <Link to='/portfolio/maven-women' >
              <h5>Maven Women</h5>
              <p>View work</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div id='don-curvino' className='portfolio-item h200' data-item='4'>
          <div className='mask'>
            <Link to='/portfolio/don-curvino' >
              <h5>Don Curvino</h5>
              <p>View work</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-md-8'>
        <div id='mastiff' className='portfolio-item h415' data-item='5'>
          <div className='mask'>
            <Link to='/portfolio/mastiff' >
              <h5>Mastiff</h5>
              <p>View work</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='row'>
          <div className='col-md-12'>
            <div id='keteka' className='portfolio-item h200' data-item='6'>
              <div className='mask'>
                <Link to='/portfolio/keteka' >
                  <h5>Keteka</h5>
                  <p>View work</p>
                </Link>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div id='sigora' className='portfolio-item h200' data-item='7'>
              <div className='mask'>
                <Link to='/portfolio/sigora' >
                  <h5>Sigora</h5>
                  <p>View work</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div id='adelante' className='portfolio-item h415' data-item='8'>
          <div className='mask'>
            <Link to='/portfolio/adelante' >
              <h5>Adelante</h5>
              <p>View work</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='row'>
          <div className='col-md-12'>
            <div id='dreaming-out-loud' className='portfolio-item h200' data-item='9'>
              <div className='mask'>
                <Link to='/portfolio/dreaming-out-loud' >
                  <h5>Dreaming Out Loud</h5>
                  <p>View work</p>
                </Link>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div id='agora' className='portfolio-item h200' data-item='10'>
              <div className='mask'>
                <Link to='/portfolio/agora' >
                  <h5>Agora Partnerships</h5>
                  <p>View work</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div id='africa-b2b' className='portfolio-item h415' data-item='11'>
          <div className='mask'>
            <Link to='/portfolio/africa-b2b' >
              <h5>AfricaB2B</h5>
              <p>View work</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='row'>
          <div className='col-md-12'>
            <div id='plympton-farms' className='portfolio-item h200' data-item='13'>
              <div className='mask'>
                <Link to='/portfolio/plympton-farms' >
                  <h5>Plympton Farms</h5>
                  <p>View work</p>
                </Link>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div id='i-dev' className='portfolio-item h200' data-item='14'>
              <div className='mask'>
                <Link to='/portfolio/i-dev' >
                  <h5>I-Dev</h5>
                  <p>View work</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-8'>
        <div id='fair-trade-fashion' className='portfolio-item h415' data-item='15'>
          <div className='mask'>
            <Link to='/portfolio/fair-trade-fashion' >
              <h5>Fair Trade Fashion</h5>
              <p>View work</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='portfolio-item h400' data-item='12'>
          <div className='mask'>
            <Link
              to=''
              onClick={(event) => {
                event.preventDefault()
                window.open('https://drive.google.com/file/d/1RsZqDAMarWBrYQLu4NRRtX8J-t9PhvkA/view?usp=sharing')
              }}
            >
              <h5>RioSlum Studio</h5>
              <p>View Our Full Portfolio</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Portfolio
