/*global jarallax*/

import React from 'react'

const ParallaxBanner = props => {
  jarallax(document.querySelectorAll('.jarallax'))

  return (
    <div className={`jarallax ${props.className}`} style={{
      backgroundImage: `url(${props.image})`,
      backgroundPosition: '50% 30%'
    }}>
      {props.children}
    </div>
  )
}

export default ParallaxBanner
