import React, {Component} from 'react'
import axios from 'axios'

const defaultForm = {
  name: {
    placeholder: 'Name',
    error: false
  },
  email: {
    placeholder: 'Email',
    error: false
  },
  message: {
    placeholder: 'Message',
    error: false
  },
  button: {
    state: 'state-await',
    text: 'send message!'
  }
}

class ContactForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      name: {
        placeholder: 'Name',
        error: false
      },
      email: {
        placeholder: 'Email',
        error: false
      },
      message: {
        placeholder: 'Message',
        error: false
      },
      button: {
        state: 'state-await',
        text: 'send message'
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.delivery = this.delivery.bind(this)
  }

  handleSubmit (event) {
    event.preventDefault()

    const name = event.target.name.value
    const email = event.target.email.value
    const message = event.target.message.value

    if (name !== '') {
      if (email !== '') {
        if (this.validateEmail(email)) {
          if (message !== '') {
            this.setState({
              button: {
                state: 'state-sending',
                text: 'Sending...'
              }
            })

            setTimeout(() => {
              this.delivery(name, email, message)
            }, 2000)
          } else {
            document.getElementById('message').focus()
            this.setState({
              message: {
                placeholder: 'Please provide your message',
                error: true
              }
            })
          }
        } else {
          document.getElementById('email').focus()
          this.setState({
            email: {
              placeholder: 'Please provide an valid email address',
              error: true
            }
          })
        }
      } else {
        document.getElementById('email').focus()
        this.setState({
          email: {
            placeholder: 'Please provide your email',
            error: true
          }
        })
      }
    } else {
      document.getElementById('name').focus()
      this.setState({
        name: {
          placeholder: 'Please provide your name',
          error: true
        }
      })
    }
  }

  delivery (name, email, message) {
    axios({
      method: 'post',
      url: 'https://www.rioslumstudio.com/email.php',
      params: {
        name: name,
        email: email,
        message: message
      }
    }).then(
      response => {
        this.setState({
          button: {
            state: 'state-success',
            text: 'Your message was sent'
          }
        })

        setTimeout(() => {
          document.getElementById('name').value = ''
          document.getElementById('email').value = ''
          document.getElementById('message').value = ''

          this.setState(defaultForm)
        }, 2000)
      },
      reject => {
        console.log('reject', reject)
      }
    )
  }

  handleInputChange (event) {
    this.setState(defaultForm)
  }

  validateEmail (email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
  }

  render () {
    let subtitle = ''

    if (this.props.subtitle !== '') {
      subtitle = <p className='text'>{this.props.subtitle}</p>
    }

    return (
      <div id='contact-form' className='contact-form'>
        <div className='container'>
          <h5 className='title'>{this.props.title}</h5>
          {subtitle}
          <form className='message' onSubmit={this.handleSubmit}>
            <div className='form-group'>
              <input
                className={`form-control ${(this.state.name.error) ? 'error' : ''}`}
                id='name'
                name='name'
                placeholder={this.state.name.placeholder}
                onChange={this.handleInputChange}
                type='text' />
            </div>
            <div className='form-group'>
              <input
                className={`form-control ${(this.state.email.error) ? 'error' : ''}`}
                id='email'
                name='email'
                placeholder={this.state.email.placeholder}
                onChange={this.handleInputChange}
                type='email' />
            </div>
            <div className='form-group'>
              <textarea
                className={`form-control ${(this.state.message.error) ? 'error' : ''}`}
                id='message'
                name='message'
                rows='4'
                placeholder={this.state.message.placeholder}
                onChange={this.handleInputChange} />
            </div>
            <button
              type='submit'
              className={`button color--fuscagrey text-uppercase m-auto ${this.state.button.state}`} >{this.state.button.text}</button>
          </form>
        </div>
      </div>
    )
  }
}

export default ContactForm
