import React from 'react';
import { Link } from 'react-router-dom';

import closeMenu from '../assets/menu_close.svg';

const NavSmall = props => {
  const menuIsOpen = (!props.menuIsOpen) ? 'hidden' : ''

  return (
    <aside className={`nav-small clearfix ${menuIsOpen}`}>
      <figure className='clearfix'>
        <img className='close' src={closeMenu} alt='' onClick={props.toogleMenuSmall} />
      </figure>

      <nav>
        <ul className='menu'>
          <li className='menu-item'><Link to='/' onClick={props.toogleMenuSmall}>Work</Link></li>
          <li className='menu-item'><Link to='/about' onClick={props.toogleMenuSmall}>About</Link></li>
          <li className='menu-item'><Link to='/services' onClick={props.toogleMenuSmall}>Services</Link></li>
          <li className='menu-item'><Link to='/startups' onClick={props.toogleMenuSmall}>Startups</Link></li>
          <li className='menu-item'><Link to='/contact' onClick={props.toogleMenuSmall}>Contact</Link></li>
        </ul>
      </nav>
    </aside>
  )
}

export default NavSmall
