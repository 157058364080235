import React, { Component } from 'react';
import Header from './Header';
import HeaderFixed from './HeaderFixed';
import SubMenu from './SubMenu';
import ParallaxBannerVideo from './ParallaxBannerVideo';
import ParallaxBanner from './ParallaxBanner';
import NavSmall from '../components/NavSmall';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Slider from 'react-slick';
import Config from '../config';

import parallaxStartup from '../assets/images/parallax/parallax_aboutTwo.jpg';
import parallaxStartupVideo from '../assets/videos/startup.mp4';

import partnersAgora from '../assets/agora.png';
import partnersSpeed from '../assets/seedspot.png';

import testimonialsKeteka from '../assets/images/keteka.png';
import testimonialsAdelante from '../assets/images/adelante.png';
import testimonialsUncommoncacao from '../assets/images/uncommoncacao.png';
import testimonialsEcoola from '../assets/images/ecoola.png';
import testimonialsMavenwomen from '../assets/images/mavenwomen.png';
import testimonialsWec from '../assets/images/wec.png';

class Startup extends Component {
  componentWillMount () {
    this.props.handleCurrentPage(this.props.location.pathname)
  }

  render () {
    const settings = {
      dots: false,
      infinite: true,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
      // autoplay: true,
      // autoplaySpeed: 5000
    }

    return (
      <div>
        <NavSmall menuIsOpen={this.props.menuIsOpen} toogleMenuSmall={this.props.toogleMenuSmall} />
        <Header currentPage={this.props.currentPage} toogleMenuSmall={this.props.toogleMenuSmall} className='startup'>
          <SubMenu links={Config.components.startups.links} />
        </Header>
        <HeaderFixed
          className='startup'
          currentPage={this.props.currentPage}
          toogleMenuSmall={this.props.toogleMenuSmall}
        >
          <SubMenu links={Config.components.startups.links} showSubMenu={this.props.showSubMenu} />
        </HeaderFixed>
        <div className='main startups'>
          <ParallaxBannerVideo
            className='mb-50px'
            video={`mp4:${parallaxStartupVideo}`}
            options={{
              // imgSrc: './assets/videos/startupBg.jpg'
            }}
          >
            <h2 className='tg--text-2'>Startups</h2>
          </ParallaxBannerVideo>

          <div className='container text-center'>
            <h2 id='why-startups' className='title mb-30px'>why startups?</h2>
            <p className='text mb-30px'>The RioSlum Studio leadership team met while working at a Latin American business accelerator. Working with startups and social enterprises is in the very DNA of our business and is the inspiration for our name.</p>
            <p className='text mb-30px'>RioSlum Studio has a practice dedicated to helping entrepreneurs across the globe achieve their business goals through branding, design, marketing, communications, and videography.</p>

            <span className='sep-1 m-y-60px' />
            <h2 id='rioslum-studio-startups' className='title mb-30px'>RioSlum Studio & Startups</h2>
            <p className='text mb-60px'>RioSlum Studio partners with accelerators, incubators, and entrepreneur networks around the world to offer flexible, affordable services to groundbreaking startups through preferred vendor engagements. On a case-by-case basis, RioSlum Studio will work with startups outside of existing partnerships. Below are our current entrepreneur-oriented partnerships:</p>

            <ul className='images mb-60px'>
              <li className='image'>
                <figure>
                  <img src={partnersAgora} alt='' />
                </figure>
              </li>
              <li className='image'>
                <figure>
                  <img src={partnersSpeed} alt='' />
                </figure>
              </li>
            </ul>

            {/* <ul className='items mb-60px'>
                <li className='item'>
                  <figure className='image swipe'>
                    <img src='assets/icon2.svg'/>
                  </figure>
                  <p className='name'>Rio partners with an existing network.</p>
                </li>
                <li className='item'>
                  <figure className='image swipe'>
                    <img src='assets/icon2.svg'/>
                  </figure>
                  <p className='name'>Rio works with network entrepreneurs on a one-on-one basis </p>
                </li>
                <li className='item'>
                  <figure className='image swipe'>
                    <img src='assets/icon2.svg'/>
                  </figure>
                  <p className='name'>Delivers premium services at affordable, flexible rates</p>
                </li>
              </ul> */}

          </div>
          <ParallaxBanner
            className='parallax startup-1 mb-120px'
            image={parallaxStartup}
            options={{
              speed: 0.2,
              automaticResize: true
            }} />
          <h5 id='testimonials-title' className='testimonials-title'>Testimonials</h5>
          <Slider {...settings}>
            <div className='pd-t-15px'>
              <div id='testimonials' className='testimonials text-center'>
                <p className='text'>What sets RioSlum Studio apart from the competition is their ability to truly listen, comprehend, and communicate clearly this vision of their clients. The work they deliver is extremely professional and had an immediate positive impact on our bottom line objectives.</p>
                <img className='image' src={testimonialsKeteka} alt='' />
                <p className='author'>Kyle Wiggins</p>
                <p className='company'>Keteka</p>
              </div>
            </div>
            <div className='pd-t-15px'>
              <div id='testimonials' className='testimonials text-center'>
                <p className='text'>My primary objective in working with RioSlum was to define our brand identity. Elysa worked directly with our management team to iterate on our brand voice and aesthetic, and she consistently went above and beyond to deliver a product we are ultimately very happy with. I would recommend RioSlum to friend, and look forward to working with the firm again in the future!</p>
                <img className='image' src={testimonialsAdelante} alt='' />
                <p className='author'>Peter Sacco</p>
                <p className='company'>Adelante</p>
              </div>
            </div>
            <div className='pd-t-15px'>
              <div id='testimonials' className='testimonials text-center'>
                <p className='text'>We found RioSlum’s team incredibly responsive, detail-oriented, and thoughtful in their work to help us shape Uncommon Cacao’s new brand. They were a joy to work with and produced a wide variety of useful brand elements for our business.</p>
                <img className='image' src={testimonialsUncommoncacao} alt='' />
                <p className='author'>Emily Stone</p>
                <p className='company'>Uncommon Cacao</p>
              </div>
            </div>
            <div className='pd-t-15px'>
              <div id='testimonials' className='testimonials text-center'>
                <p className='text'>We were extremely pleased with the work the RioSlum Studio did for Eco Ola. RioSlum Studio has a great process where they quickly understood exactly what we were looking for and delivered it. Their design and communication skills are top-notch.</p>
                <img className='image' src={testimonialsEcoola} alt='' />
                <p className='author'>William Park</p>
                <p className='company'>Eco Ola</p>
              </div>
            </div>
            <div className='pd-t-15px'>
              <div id='testimonials' className='testimonials text-center'>
                <p className='text'>It was a pleasure to work with Rio Slum Studio on an exclusive video showcasing my ethical fashion company, Maven Women, for the State of Fashion convening. RioSlum Studio really understands what it's like to be an entrepreneur in this space and they are able to work well within the resources that a young brand has. The team is friendly, fun, great with communication, and respects deadlines and price constraints. The final product is a beautiful narrative that is perfect for this convening and can also be used more broadly.</p>
                <img className='image' src={testimonialsMavenwomen} alt='' />
                <p className='author'>Rebecca Ballard</p>
                <p className='company'>Maven Women</p>
              </div>
            </div>
            <div className='pd-t-15px'>
              <div id='testimonials' className='testimonials text-center'>
                <p className='text'>They have rebranded and guided us in implementing an international campaign; designed and conducted valuable market research on our service and stakeholders; are in the midst of redeveloping our overall look and messaging with vibrant creativity; and continue to provide results that continue to please not only our team but also, importantly, our client [the US State Department] - which is no small feat! RioSlum’s creative and organizational talent, collaborative approach, and commitment to service are exceptional - we couldn’t have selected a better partner.</p>
                <img className='image' src={testimonialsWec} alt='' />
                <p className='author'>Gwen Davidow</p>
                <p className='company'>WEC</p>
              </div>
            </div>
          </Slider>
          <span className='sep-1' style={{ maxWidth: '800px', margin: '60px auto' }} />
        </div>
        <ContactForm title='Consultancy' subtitle='If you are a startup looking to build your brand, feel free to schedule a free 15-minute consulting session to explore how RioSlum Studio can help.' />
        <Footer page={this.props.currentPage} />
      </div>
    )
  }
}

export default Startup
