const Config = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  components: {
    about: {
      links: [
        {
          name: 'About Rio',
          slug: 'about-rio'
        },
        {
          name: 'Team',
          slug: 'team'
        },
        {
          name: 'Staff Locations',
          slug: 'staff-locations'
        }
      ]
    },
    services: {
      links: [
        {
          name: 'Branding',
          slug: 'branding'
        },
        {
          name: 'design & web',
          slug: 'design-web'
        },
        {
          name: 'Messaging',
          slug: 'messaging'
        },
        {
          name: 'Strategy',
          slug: 'strategy'
        },
        {
          name: 'Video Production',
          slug: 'video-production'
        }
      ]
    },
    startups: {
      links: [
        {
          name: 'why startups?',
          slug: 'why-startups'
        },
        {
          name: 'RioSlum Studio & Startups',
          slug: 'rioslum-studio-startups'
        },
        {
          name: 'testimonials',
          slug: 'testimonials-title'
        },
        {
          name: 'free consultancy',
          slug: 'contact-form'
        }
      ]
    },
    portfolio: {
      images: {
        'adelante': [
          {
            original: './img/portfolio/carousel/adelante/01.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio developed a comprehensive brand package based on Adelante’s refreshed assets that included items ranging from one pager and PPT templates to t-shirts and business cards.'
          },
          {
            original: './img/portfolio/carousel/adelante/02.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio developed a comprehensive brand package based on Adelante’s refreshed assets that included items ranging from one pager and PPT templates to t-shirts and business cards.'
          },
          {
            original: './img/portfolio/carousel/adelante/03.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio developed a comprehensive brand package based on Adelante’s refreshed assets that included items ranging from one pager and PPT templates to t-shirts and business cards.'
          }
        ],
        'power-to-grow': [
          {
            original: './img/portfolio/carousel/power-to-grow/01.jpg',
            title: 'La Red & Challenge Brand Development',
            description: 'RioSlum Studio developed new brands for both La Red and its Challenge entitled “Power to Grow”. Assets created included a new La Red logo, a new Challenge logo, and complete brand packages for both.'

          },
          {
            original: './img/portfolio/carousel/power-to-grow/02.jpg',
            title: 'Challenge Digital Ads',
            description: 'Designed, launched, and placed a series of digital ads in English and in Spanish to attract applicants to the “Power to Grow” Challenge.'

          },
          {
            original: './img/portfolio/carousel/power-to-grow/03.jpg',
            title: 'Challenge Website',
            description: ' For the launch of the 2018 “Power to Grow” Challenge, RioSlum Studio designed, launched, and maintained a microsite in both English and Spanish.'

          },
          {
            original: './img/portfolio/carousel/power-to-grow/04.jpg',
            title: 'Challenge Digital Ads',
            description: 'Designed, launched, and placed a series of digital ads in English and in Spanish to attract applicants to the “Power to Grow” Challenge.'

          },
          {
            original: './img/portfolio/carousel/power-to-grow/05.jpg',
            title: 'Challenge Digital Ads',
            description: 'Designed, launched, and placed a series of digital ads in English and in Spanish to attract applicants to the “Power to Grow” Challenge.'
          }
        ],
        'pay-by-court': [
          {
            original: './img/portfolio/carousel/pay-by-court/01.jpg',
            title: 'Sales Collateral',
            description: 'RioSlum Studio refined the client concept to create a new logo and wordmark. RioSlum Studio applied these new standards across a variety of print and digital sales collateral.'
          },
          {
            original: './img/portfolio/carousel/pay-by-court/02.jpg',
            title: 'Sales Collateral',
            description: 'RioSlum Studio refined the client concept to create a new logo and wordmark. RioSlum Studio applied these new standards across a variety of print and digital sales collateral.'
          },
          {
            original: './img/portfolio/carousel/pay-by-court/03.jpg',
            title: 'Pitch Deck',
            description: 'RioSlum Studio refined client language and designed a pitch deck aimed at attracting investors.'
          }
        ],
        'maven-women': [
          {
            original: './img/portfolio/carousel/maven-women/video-01.jpg',
            title: 'Maven Women Short Film',
            description: 'RioSlum Studio storyboarded, produced, and edited a short film for Maven Women to premier at the State of Fashion event in Arnhem, Holland. The film was also featured on Maven Women\'s website and social media.',
            embedUrl: 'https://player.vimeo.com/video/277561169?autoplay=1&autopause=0',
            renderItem: ''
          }
        ],
        'don-curvino': [
          {
            original: './img/portfolio/carousel/don-curvino/01.jpg',
            title: 'Logo & Wordmark',
            description: 'RioSlum Studio designed the company’s logo and wordmark, selecting primary and secondary fonts and color options.'
          },
          {
            original: './img/portfolio/carousel/don-curvino/02.jpg',
            title: 'Stationery',
            description: 'RioSlum Studio incorporated elements from the logo and and wordmark to create a distinct company stationery (such as the business card here).'
          },
          {
            original: './img/portfolio/carousel/don-curvino/03.jpg',
            title: 'Stationery',
            description: 'RioSlum Studio incorporated elements from the logo and and wordmark to create a distinct company stationery (such as the business card here).'
          },
          {
            original: './img/portfolio/carousel/don-curvino/04.jpg',
            title: 'Logo Variations',
            description: 'RioSlum Studio created logo variations to be used on a range of materials.'
          }
        ],
        'sigora': [
          {
            original: './img/portfolio/carousel/sigora/01.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio conducted a brand study, produced a conceptual direction, and designed a brand package with design standards, language standards, communications strategy, and visual and language templates'
          },
          {
            original: './img/portfolio/carousel/sigora/02.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio conducted a brand study, produced a conceptual direction, and designed a brand package with design standards, language standards, communications strategy, and visual and language templates'
          },
          {
            original: './img/portfolio/carousel/sigora/03.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio conducted a brand study, produced a conceptual direction, and designed a brand package with design standards, language standards, communications strategy, and visual and language templates'
          },
          {
            original: './img/portfolio/carousel/sigora/04.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio conducted a brand study, produced a conceptual direction, and designed a brand package with design standards, language standards, communications strategy, and visual and language templates'
          },
          {
            original: './img/portfolio/carousel/sigora/05.jpg',
            title: 'Pitch Deck',
            description: 'RioSlum Studio refined client language and designed an animated pitch deck.'
          }
        ],
        'mastiff': [
          {
            original: './img/portfolio/carousel/mastiff/01.jpg',
            title: 'Product Design',
            description: 'RioSlum Studio designed the Mastiff router, working with the Mastiff team to address technical and 3D printing needs.'
          },
          {
            original: './img/portfolio/carousel/mastiff/02.jpg',
            title: 'Product Design',
            description: 'RioSlum Studio designed the Mastiff router, working with the Mastiff team to address technical and 3D printing needs.'
          },
          {
            original: './img/portfolio/carousel/mastiff/03.jpg',
            title: 'Product Design',
            description: 'RioSlum Studio designed the Mastiff router, working with the Mastiff team to address technical and 3D printing needs.'
          }
        ],
        'keteka': [
          {
            original: './img/portfolio/carousel/keteka/01.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio created a brand package that included language standards, design standards, and adaptable templates.'
          },
          {
            original: './img/portfolio/carousel/keteka/02.jpg',
            title: 'Brand Applications',
            description: 'RioSlum Studio applied the brand to a variety of collateral, including postcards, social media posts, and physical collateral. RioSlum Studio also outlined best practices for asset application.'
          },
          {
            original: './img/portfolio/carousel/keteka/03.jpg',
            title: 'Web Pages',
            description: 'RioSlum Studio adapted select Keteka web pages to incorporate the updated, cinematic brand, improve UX, and increase conversions.'
          }
        ],
        'africa-b2b': [
          {
            original: './img/portfolio/carousel/africa-b2b/01.jpg',
            title: 'Pitch Deck',
            description: 'RioSlum Studio refined client language and created an original one one-pager and pitch deck (design and language).'
          },
          {
            original: './img/portfolio/carousel/africa-b2b/02.jpg',
            title: 'Brand Identity',
            description: 'RioSlum Studio created brand guidelines, enabling the client to maintain its brand identity across a variety of collateral.'
          },
          {
            original: './img/portfolio/carousel/africa-b2b/03.jpg',
            title: 'One Pager',
            description: 'RioSlum Studio refined client language and created an original one one-pager and pitch deck (design and language).'
          }
        ],
        'dreaming-out-loud': [
          {
            original: './img/portfolio/carousel/dreaming-out-loud/01.jpg',
            title: 'Annual Report',
            description: 'RioSlum Studio produced Dreaming Out Loud’s 2017 Annual Report to comply with updated brand standards.'
          },
          {
            original: './img/portfolio/carousel/dreaming-out-loud/02.jpg',
            title: 'Flyer',
            description: 'RioSlum Studio designed small flyers that aligned with the updated brand standards and presented ample information in an easily digestible piece of print collateral.'
          },
          {
            original: './img/portfolio/carousel/dreaming-out-loud/03.jpg',
            title: 'Food Panel Flyer',
            description: 'RioSlum Studio produced collateral aimed at highlighting the vivaciousness of the brand and appealing a wide variety of ages.'
          }
        ],
        'uncommon-cacao': [
          {
            original: './img/portfolio/carousel/uncommon-cacao/01.jpg',
            title: '',
            description: ''
          },
          {
            original: './img/portfolio/carousel/uncommon-cacao/02.jpg',
            title: '',
            description: ''
          },
          {
            original: './img/portfolio/carousel/uncommon-cacao/03.jpg',
            title: '',
            description: ''
          },
          {
            original: './img/portfolio/carousel/uncommon-cacao/04.jpg',
            title: '',
            description: ''
          }
        ],
        'agora': [
          {
            original: './img/portfolio/carousel/agora/video-01.jpg',
            title: 'Agora Partnerships - Promotional Film',
            description: 'RioSlum Studio worked with the Agora team from concept to on-the-ground videography to post-production of this informational film in English and Spanish.',
            embedUrl: 'https://player.vimeo.com/video/304255803?autoplay=1&autopause=0',
            renderItem: ''
          },
          /*{
            original: './img/portfolio/carousel/agora/video-02.jpg',
            title: 'Cataliza Chile Film',
            description: 'RioSlum Studio created a promotional video for an event co-hosted by Agora, Fondo Inversión Social, and ZomaLab (a Walton Family Foundation) to highlight development in Chile.',
            embedUrl: 'https://player.vimeo.com/video/285935177?autoplay=1&autopause=0',
            renderItem: ''
          },
          {
            original: './img/portfolio/carousel/agora/video-03.jpg',
            title: 'Agora Entrepreneur Recruiting Film',
            description: 'RioSlum Studio worked with the Agora team from concept to on-the-ground videography to post-production of this promotional video in English and Spanish. Additionally, RioSlum Studio provided scripting and interview training.',
            embedUrl: 'https://player.vimeo.com/video/307115770?autoplay=1&autopause=0',
            renderItem: ''
          },
          {
            original: './img/portfolio/carousel/agora/video-04.jpg',
            title: 'Agora Consultant Recruiting Film',
            description: 'RioSlum Studio worked from concept to on-the-ground videography to post-production of this promotional video in English and Spanish. RioSlum Studio also reviewed, selected, and edited existing footage to supplement shots captured by the Rio team on-site.',
            embedUrl: 'https://player.vimeo.com/video/307105212?autoplay=1&autopause=0',
            renderItem: ''
          },*/
          {
            original: './img/portfolio/carousel/agora/01.jpg',
            title: 'Website',
            description: ' RioSlum Studio applied the new language and brand standards to an original website. The site features strong visuals, interactive design, and friendly UX / UI.'
          },
          {
            original: './img/portfolio/carousel/agora/02.jpg',
            title: 'Cataliza Chile',
            description: 'RioSlum Studio created a brand for a forum co-hosted by Fondo Inversión Social and ZomaLab (a Walton Family Foundation). The brand draws influences from a prism, to mirror the effect a coalition of individuals can have in "lighting up" all parts of a country.'
          },
          {
            original: './img/portfolio/carousel/agora/03.jpg',
            title: 'Event Collateral',
            description: 'RioSlum Studio applied the organization\'s brand to create all collateral and giveaways associated with the organization\'s bi-annual entrepreneur retreat.'
          }
        ],
        'plympton-farms': [
          {
            original: './img/portfolio/carousel/plympton-farms/01.jpg',
            title: 'Logo',
            description: 'RioSlum Studio executed a complete brand discovery process to develop a logo that captured the earthiness of the product, the importance of the producers, and the community of Guyana.'
          },
          {
            original: './img/portfolio/carousel/plympton-farms/02.jpg',
            title: 'Brand Package',
            description: 'RioSlum Studio created a comprehensive brand package, which included all language and visual standards along with hand-panted product features.'
          },
          {
            original: './img/portfolio/carousel/plympton-farms/03.jpg',
            title: 'Flyers',
            description: 'RioSlum Studio applied brand standards to customizable collateral, including flyers, one-pagers, and product packaging.'
          }
        ],
        'i-dev': [
          {
            original: './img/portfolio/carousel/i-dev/01.jpg',
            title: 'Marketing Poster',
            description: 'RioSlum Studio designed a stage poster for I-DEV\'s 2018 CAPEX conference in Niarobi, Kenya.'
          },
          {
            original: './img/portfolio/carousel/i-dev/02.jpg',
            title: 'Conference Collateral',
            description: 'RioSlum Studio designed key conference collateral for I-DEV\'s 2018 CAPEX conference in Niarobi, Kenya.'
          },
          {
            original: './img/portfolio/carousel/i-dev/03.jpg',
            title: 'Conference Collateral',
            description: 'RioSlum Studio designed key conference collateral for I-DEV\'s 2018 CAPEX conference in Niarobi, Kenya.'
          }
        ],
        'fair-trade-fashion': [
          {
            original: './img/portfolio/carousel/fair-trade-fashion/video-01.jpg',
            title: 'Fair Trade Fashion Show - Runway Looks',
            description: 'RioSlum Studio created a runway looks video that balanced models, sponsors, and designers from the 2018 fashion show.',
            embedUrl: 'https://player.vimeo.com/video/323557557?autoplay=1&autopause=0',
            renderItem: ''
          },
          /*{
            original: './img/portfolio/carousel/fair-trade-fashion/video-02.jpg',
            title: 'Fair Trade Fashion Show - Promotional Video',
            description: 'RioSlum Studo produced a short informational film- from concept to final - of the Fair Trade Fashion Show. The film combined shots from throughout the day with topline information on the event.',
            embedUrl: 'https://player.vimeo.com/video/320404284?autoplay=1&autopause=0',
            renderItem: ''
          },*/
          {
            original: './img/portfolio/carousel/fair-trade-fashion/01.jpg',
            title: 'Fashion Show Collateral',
            description: 'RioSlum Studio designed event collateral for the Fair Trade Fashion Show\'s 2018 runway show in Los Angeles, CA.'
          },
          {
            original: './img/portfolio/carousel/fair-trade-fashion/02.jpg',
            title: 'Fashion Show Collateral',
            description: 'RioSlum Studio designed event collateral for the Fair Trade Fashion Show\'s 2018 runway show in Los Angeles, CA.'
          }
        ]
      }
    }
  }
}

export default Config
