import React, { Component } from 'react'
import Portfolio from './Portfolio'
import Header from './Header'
import HeaderFixed from './HeaderFixed'
// import Nav from './Nav'
import NavSmall from './NavSmall'
import Video from './Video'
// import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax'
// import video from './assets/video.mp4'
import ParallaxBanner from './ParallaxBanner'
import { Link } from 'react-router-dom'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import ScrollToTop from './ScrollToTop'

import videoHome from '../assets/videos/home.mp4';
import parallaxHomeOne from '../assets/images/parallax/parallax_homeOne.jpg';
import parallaxHomeTwo from '../assets/images/parallax/parallax_homeTwo.jpg';
import iconBranding from '../assets/branding_icon.svg';
import iconDesignWeb from '../assets/designweb_icon.svg';
import iconMessaging from '../assets/messaging_icon.svg';
import iconStrategy from '../assets/strategy_icon.svg';
import iconVideo from '../assets/video_icon.svg';

class Work extends Component {
  componentWillMount () {
    this.props.handleCurrentPage(this.props.location.pathname)
    // window.scrollTo(0, 0)
    // console.log(this.props.location)
  }

  componentDidUpdate (prevProps) {
    // console.log(this.props.location, prevProps.location)

    // if (prevProps.location.hash === '#/portfolio/power-to-grow') {
    //   console.log('power-to-grow')
    //   // window.scrollTo(0, 0)
    //   document.getElementById('power-to-grow').scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'start',
    //   })
    // }
  }
  // componentDidUpdate(prevProps){
  //   if(this.props.location.hash !== prevProps.location.hash){
  //     console.log('dsafdsaf')
  //   }
  // }

  render () {
    return (
      <div>
        <ScrollToTop>
          <NavSmall menuIsOpen={this.props.menuIsOpen} toogleMenuSmall={this.props.toogleMenuSmall} />
          <Video video={`mp4:${videoHome}`} />
          <Header className='work' currentPage={this.props.currentPage} toogleMenuSmall={this.props.toogleMenuSmall} />
          <HeaderFixed
            className='work'
            currentPage={this.props.currentPage}
            toogleMenuSmall={this.props.toogleMenuSmall}
          />
          <div className='main work'>
            <Portfolio />
            <ParallaxBanner
              className='parallax work-1'
              image={parallaxHomeOne}
              options={{
                speed: 0.2
              }}
            >
              <div className='content'>
                <h5 className='title'>About Rio</h5>
                <p className='text'>RioSlum Studio is a high-concept branding, strategy, and communications firm dedicated to creating simple, memorable, and bold brands for extraordinary businesses.</p>
                <a className='button color--white text-uppercase' href='#/about'>Find out more</a>
              </div>
            </ParallaxBanner>
            <div className='section clearfix'>
              <div className='container'>
                <h5 className='title'>Services</h5>
                <p className='text'>Whether you're launching or re-launching your brand, undergoing a refresh, or seeking new audiences, RioSlum Studio will find your original voice. RioSlum Studio engages with clients on a variety of levels and offers services in both English and Spanish.</p>
                <ul className='items'>
                  <li className='item'>
                    <figure className='image swipe'>
                      <img src={iconBranding} alt='' />
                    </figure>
                    <p className='name'>branding</p>
                  </li>
                  <li className='item'>
                    <figure className='image swipe'>
                      <img src={iconDesignWeb} alt='' />
                    </figure>
                    <p className='name'>design & web</p>
                  </li>
                  <li className='item'>
                    <figure className='image swipe'>
                      <img src={iconMessaging} alt='' />
                    </figure>
                    <p className='name'>messaging</p>
                  </li>
                  <li className='item'>
                    <figure className='image swipe'>
                      <img src={iconStrategy} alt='' />
                    </figure>
                    <p className='name'>strategy</p>
                  </li>
                  <li className='item'>
                    <figure className='image swipe'>
                      <img src={iconVideo} alt='' />
                    </figure>
                    <p className='name'>video</p>
                  </li>
                </ul>
              </div>
            </div>
            <ParallaxBanner
              className='parallax work-2'
              image={parallaxHomeTwo}
              options={{
                speed: 0.2,
                automaticResize: true
              }}
            >
              <div className='content'>
                <h5 className='title'>Startup</h5>
                <p className='text'>We launched RioSlum Studio to ensure all companies, from one-person startups to international corporations, have access to the high-quality branding and communications services they need to command global attention.</p>
                <Link className='button color--white text-uppercase' to='/startups' >Find out more</Link>
              </div>
            </ParallaxBanner>
          </div>
          <ContactForm title='contact us' subtitle='Spark a conversation with RioSlum Studio.' />
          <Footer page={this.props.currentPage} />
        </ScrollToTop>
      </div>
    )
  }
}

export default Work
