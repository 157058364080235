import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import Header from "./Header";
import Config from "../config";

import galleryClose from "../assets/close.svg";

class PortfolioOpen extends Component {
  constructor() {
    super();

    this.state = {
      showVideo: {},
    };
  }

  componentWillMount() {
    this.props.handleCurrentPage(this.props.match.path);
  }

  _resetVideo() {
    this.setState({ showVideo: {} });
    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !this.state.showVideo[url];
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div className="image-gallery-image maven-women">
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <button
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            />
            <iframe
              title="Video"
              className="iframe-video"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button" />
            <img src={item.original} alt="" />
            {item.description && (
              <span
                className="image-gallery-description"
              >
                <span className="image-gallery-title">Brand Package</span>
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  _renderItem(item) {
    return (
      <div className="image-gallery-slide center">
        <div className="image-gallery-image">
          <img src={item.original} />
          <span className="image-gallery-description"><span className="image-gallery-title">{item.title}</span>{item.description}</span>
        </div>
      </div>
    )
  }

  render() {
    const isVideo = ['maven-women', 'agora', 'fair-trade-fashion']

    Object.keys(Config.components.portfolio.images).map((key) => {
      if (isVideo.indexOf(key) === -1) {
        Config.components.portfolio.images[key].map(item => {
          item.renderItem = this._renderItem.bind(this);
        });
      }
      if (isVideo.indexOf(key) > -1 ) {
        Config.components.portfolio.images[key][0].renderItem = this._renderVideo.bind(this);
      }
    })

    return (
      <div>
        <Header currentPage={this.props.currentPage} className="portfolio" />
        <div className="main portfolio">
          <Link className="close" to="/">
            <img src={galleryClose} alt="" />
          </Link>
          <ImageGallery
            items={
              Config.components.portfolio.images[this.props.match.params.name]
            }
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
          />
        </div>
      </div>
    );
  }
}

// export default PortfolioOpen
export default withRouter(PortfolioOpen);
