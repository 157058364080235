/*global jarallax*/

import React from 'react'

const Video = props => {
  jarallax(document.querySelectorAll('.jarallax'), props.options)

  return (
    <div className={`jarallax full-background`} data-jarallax-video={props.video}>
      {props.children}
    </div>
  )
}

export default Video
