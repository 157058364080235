import React, { Component } from 'react';
import Header from './Header';
import HeaderFixed from './HeaderFixed';
import SubMenu from './SubMenu';
import ParallaxBannerVideo from './ParallaxBannerVideo';
import ParallaxBanner from './ParallaxBanner';
import NavSmall from '../components/NavSmall';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Config from '../config';

import parallaxServices from '../assets/images/parallax/parallax_services.jpg';
import parallaxServicesTwo from '../assets/images/parallax/parallax_aboutTwo.jpg';
import parallaxServicesVideo from '../assets/videos/services.mp4';

import videoBranding from '../assets/videos/branding_cover.jpg';
import videoBrandingMP4 from '../assets/videos/branding.mp4';
import videoBrandingWEBM from '../assets/videos/branding.webm';

import videoDesignWeb from '../assets/videos/design-web.jpg';
import videoDesignWebMP4 from '../assets/videos/design-web.mp4';
import videoDesignWebWEBM from '../assets/videos/design-web.webm';

import videoMessaging from '../assets/videos/messaging.jpg';
import videoMessagingMP4 from '../assets/videos/messaging.mp4';
import videoMessagingWEBM from '../assets/videos/messaging.webm';

import videoStrategy from '../assets/videos/strategy.jpg';
// import videoStrategyMP4 from '../assets/videos/strategy.jpg';
// import videoStrategyWEBM from '../assets/videos/strategy.webm';

import videoVideo from '../assets/videos/video-production.jpg';
import videoVideoMP4 from '../assets/videos/video-production.mp4';
import videoVideoWEBM from '../assets/videos/video-production.webm';

import iconBranding from '../assets/branding_icon.svg';
import iconDesignWeb from '../assets/designweb_icon.svg';
import iconMessaging from '../assets/messaging_icon.svg';
import iconStrategy from '../assets/strategy_icon.svg';
import iconVideo from '../assets/video_icon.svg';

class Services extends Component {
  componentWillMount () {
    this.props.handleCurrentPage(this.props.location.pathname)
  }

  render () {
    return (
      <div>
        <NavSmall menuIsOpen={this.props.menuIsOpen} toogleMenuSmall={this.props.toogleMenuSmall} />
        <Header currentPage={this.props.currentPage} toogleMenuSmall={this.props.toogleMenuSmall} className='services'>
          <SubMenu links={Config.components.services.links} />
        </Header>
        <HeaderFixed
          className='services'
          currentPage={this.props.currentPage}
          toogleMenuSmall={this.props.toogleMenuSmall}
        >
          <SubMenu links={Config.components.services.links} showSubMenu={this.props.showSubMenu} />
        </HeaderFixed>
        <div className='main services'>
          <ParallaxBannerVideo
            className='mb-50px'
            video={`mp4:${parallaxServicesVideo}`}
            options={{
              // imgSrc: './assets/videos/servicesBg.jpg'
            }}
          >
            <h2 className='tg--text-2'>Services</h2>
          </ParallaxBannerVideo>

          <div className='section clearfix'>
            <div className='container'>
              <p className='text'>Whether you're launching, re-launching, undergoing a brand refresh, or seeking new audiences, RioSlum Studio will find your original voice. RioSlum Studio engages with clients on a variety of levels and offers services in both English and Spanish.</p>
              <ul className='items'>
                <li className='item'>
                  <figure className='image swipe'>
                    <img
                      src={iconBranding}
                      alt=''
                      onClick={() => document.getElementById('branding').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                      })} />
                  </figure>
                  <p className='name'>branding</p>
                </li>
                <li className='item'>
                  <figure className='image swipe'>
                    <img
                      src={iconDesignWeb}
                      alt=''
                      onClick={() => document.getElementById('design-web').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                      })} />
                  </figure>
                  <p className='name'>design & web</p>
                </li>
                <li className='item'>
                  <figure className='image swipe'>
                    <img
                      src={iconMessaging}
                      alt=''
                      onClick={() => document.getElementById('messaging').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                      })} />
                  </figure>
                  <p className='name'>messaging</p>
                </li>
                <li className='item'>
                  <figure className='image swipe'>
                    <img
                      src={iconStrategy}
                      alt=''
                      onClick={() => document.getElementById('strategy').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                      })} />
                  </figure>
                  <p className='name'>strategy</p>
                </li>
                <li className='item'>
                  <figure className='image swipe'>
                    <img
                      src={iconVideo}
                      alt=''
                      onClick={() => document.getElementById('video-production').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                      })} />
                  </figure>
                  <p className='name'>video</p>
                </li>
              </ul>
            </div>
          </div>

          <div className='container'>
            <span className='sep-1' />
            <div id='branding' className='service'>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <h2 className='title'>Branding</h2>
                  <p className='text'> Develop a unified, multi-dimensional identity that reflects your core values, engages your customers, and leaves a lasting impression.</p>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <ul>
                        <li><span className='arrow' />Strategy & Research</li>
                        <li><span className='arrow' />Research & Analytics</li>
                        <li><span className='arrow' />Naming</li>
                        <li><span className='arrow' />Positioning</li>
                        <li><span className='arrow' />Identity</li>
                        <li><span className='arrow' />Brand Discovery Process</li>
                        <li><span className='arrow' />Brand Implementation</li>
                        <li><span className='arrow' />Brand Development</li>
                      </ul>
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ul className='mb-20px'>
                        <li><span className='arrow' />Awareness & Evaluation </li>
                        <li><span className='arrow' />Guidelines & Governance</li>
                        <li><span className='arrow' />Brand Management</li>
                        <li><span className='arrow' />Consulting/Training</li>
                        <li><span className='arrow' />Strategic Planning</li>
                        <li><span className='arrow' />Logo Creation</li>
                        <li><span className='arrow' />Style Guidelines</li>
                        <li><span className='arrow' />Marketing Collateral & Assets</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <video controls className='video' poster={videoBranding}>
                    <source src={videoBrandingMP4} type='video/mp4' />
                    <source src={videoBrandingWEBM} type='video/webm' />
                  </video>
                </div>
              </div>
            </div>
            <span className='sep-1' />
            <div id='design-web' className='service'>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <h2 className='title'>design & web</h2>
                  <p className='text'>Bring your brand to life through simple, powerful, and emotional print and digital designs and elements.</p>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <ul>
                        <li><span className='arrow' />Marketing Design</li>
                        <li><span className='arrow' />Interactive Design</li>
                        <li><span className='arrow' />Custom Typography</li>
                        <li><span className='arrow' />Infographics</li>
                        <li><span className='arrow' />Marketing Collateral</li>
                        <li><span className='arrow' />Interactive & UX</li>
                        <li><span className='arrow' />Print Design</li>
                        <li><span className='arrow' />Website Design </li>
                      </ul>
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ul className='mb-20px'>
                        <li><span className='arrow' />Website Development</li>
                        <li><span className='arrow' />Presentation Design</li>
                        <li><span className='arrow' />Support Materials</li>
                        <li><span className='arrow' />Stationery</li>
                        <li><span className='arrow' />Brand Merchandise</li>
                        <li><span className='arrow' />Packaging</li>
                        <li><span className='arrow' />E-commerce</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <video controls className='video' poster={videoDesignWeb}>
                    <source src={videoDesignWebMP4} type='video/mp4' />
                    <source src={videoDesignWebWEBM} type='video/webm' />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <ParallaxBanner
            className='parallax services-1'
            image={parallaxServices}
            options={{
              speed: 0.2,
              automaticResize: true
            }} />
          <div className='container'>
            <div id='messaging' className='service'>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <h2 className='title'>messaging</h2>
                  <p className='text'>Create clear and effective messaging that tells your story while also engaging, informing, and inspiring your customers and your team.</p>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <ul>
                        <li><span className='arrow' />Brand Tone</li>
                        <li><span className='arrow' />Slogans</li>
                        <li><span className='arrow' />Mission & Vision</li>
                        <li><span className='arrow' />Taglines</li>
                        <li><span className='arrow' />Key Messaging</li>
                        <li><span className='arrow' />Messaging Objectives</li>
                        <li><span className='arrow' />Talking Points</li>
                        <li><span className='arrow' />Media Training</li>
                      </ul>
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ul className='mb-20px'>
                        <li><span className='arrow' />Segmented Messaging</li>
                        <li><span className='arrow' />Language Templates & Structuring</li>
                        <li><span className='arrow' />Content Audits</li>
                        <li><span className='arrow' />Social Media Messaging</li>
                        <li><span className='arrow' />Hashtag Selection</li>
                        <li><span className='arrow' />Brand Copy</li>
                        <li><span className='arrow' />Headlines</li>
                        <li><span className='arrow' />Story Identification</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <video controls className='video' poster={videoMessaging}>
                    <source src={videoMessagingMP4} type='video/mp4' />
                    <source src={videoMessagingWEBM} type='video/webm' />
                  </video>
                </div>
              </div>
            </div>
            <span className='sep-1' />
            <div id='strategy' className='service'>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <h2 className='title'>strategy</h2>
                  <p className='text'>Position your brand to identify and engage with a larger audience while leveraging communications to convert customers into allies and ambassadors.</p>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <ul>
                        <li><span className='arrow' />Audience Research & Analytics </li>
                        <li><span className='arrow' />Customer Discovery & Analytics</li>
                        <li><span className='arrow' />Social Media & Marketing Audits</li>
                        <li><span className='arrow' />Search Engine Optimization (SEO)</li>
                        <li><span className='arrow' />Search Engine Marketing (SEM) </li>
                        <li><span className='arrow' />Content Strategy & Governance</li>
                        <li><span className='arrow' />Social Media Strategy</li>
                        <li><span className='arrow' />Integrated Marketing Campaigns</li>
                      </ul>
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ul className='mb-20px'>
                        <li><span className='arrow' />Copywriting & Copyediting</li>
                        <li><span className='arrow' />Business Dev. & Sales Strategy</li>
                        <li><span className='arrow' />Communication Strategy</li>
                        <li><span className='arrow' />Outlet Targeting & Tiers</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <figure>
                    <img className='image' src={videoStrategy} alt='' />
                  </figure>
                  {/* <video muted loop controls className='video' poster={videoStrategy}>
                    <source src={videoStrategyMP4} type='video/mp4' />
                    <source src={videoStrategyWEBM} type='video/webm' />
                  </video> */}
                </div>
              </div>
            </div>
            <span className='sep-1' />
            <div id='video-production' className='service'>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <h2 className='title'>video production</h2>
                  <p className='text'>Attract attention to your brand with evocative live action and videographic content.</p>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <ul className='mb-20px'>
                        <li><span className='arrow' />Videography & Post-Production</li>
                        <li><span className='arrow' />Motion Graphics</li>
                        <li><span className='arrow' />Art Direction</li>
                        <li><span className='arrow' />Script & Storyboard</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <video controls className='video' poster={videoVideo}>
                    <source src={videoVideoMP4} type='video/mp4' />
                    <source src={videoVideoWEBM} type='video/webm' />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <ParallaxBanner
            className='parallax services-2'
            image={parallaxServicesTwo}
            options={{
              speed: 0.2,
              automaticResize: true
            }} />
        </div>
        <ContactForm title='contact us' subtitle='Spark a conversation with RioSlum Studio.' />
        <Footer page={this.props.currentPage} />
      </div>
    )
  }
}

export default Services
