import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Switch, HashRouter as Router, Route, withRouter } from 'react-router-dom';

import Work from '../components/Work';
import About from '../components/About';
import Contact from '../components/Contact';
import Services from '../components/Services';
import Startup from '../components/Startup';
import PortfolioOpen from '../components/PortfolioOpen';

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentPage: 'work',
      menuIsOpen: false,
      showSubMenu: true
    }
  }

  componentDidMount () {
    let oldScroll = 0
    window.addEventListener('scroll', (e) => {
      const scrollDirection = oldScroll > window.scrollY

      if (scrollDirection !== this.state.showSubMenu) {
        this.setState({ showSubMenu: oldScroll > window.scrollY })
      }

      oldScroll = window.scrollY
    }, false)
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      const hash = prevProps.location.hash.split('/')

      if (hash[1] === 'portfolio') {
        console.log(hash[1], hash[2])

        setTimeout(() => {
          document.getElementById(hash[2]).scrollIntoView()
        }, 200)
      }
    }
  }

  handleCurrentPage (page) {
    switch (page) {
      case '/':
        this.setState({currentPage: 'work'})
        break
      case '/about':
        this.setState({currentPage: 'about'})
        break
      case '/services':
        this.setState({currentPage: 'services'})
        break
      case '/startups':
        this.setState({currentPage: 'startups'})
        break
      case '/contact':
        this.setState({currentPage: 'contact'})
        break
      case '/portfolio/:name':
        this.setState({currentPage: 'portfolio'})
        break
      default:
        this.setState({currentPage: 'work'})
        break
    }
  }

  toogleMenuSmall () {
    this.setState(prevState => ({
      menuIsOpen: !prevState.menuIsOpen
    }))
  }

  render () {
    return (
      <div className='App' data-page={this.state.currentPage}>
        <div>
        
          <Router>
            <Route render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.pathname}
                  classNames='fade'
                  timeout={500}>
                  <Switch location={location}>

                    <Route exact path='/' render={props => 
                      <Work
                        {...props}
                        handleCurrentPage={this.handleCurrentPage.bind(this)}
                        currentPage={this.state.currentPage}
                        showSubMenu={this.state.showSubMenu}
                        menuIsOpen={this.state.menuIsOpen}
                        toogleMenuSmall={this.toogleMenuSmall.bind(this)} />}
                    />

                    <Route exact path='/about' render={props =>
                      <About
                        {...props}
                        handleCurrentPage={this.handleCurrentPage.bind(this)}
                        currentPage={this.state.currentPage}
                        showSubMenu={this.state.showSubMenu}
                        menuIsOpen={this.state.menuIsOpen}
                        toogleMenuSmall={this.toogleMenuSmall.bind(this)} />}
                    />

                    <Route exact path='/services' render={props => 
                      <Services
                        {...props}
                        handleCurrentPage={this.handleCurrentPage.bind(this)}
                        currentPage={this.state.currentPage}
                        showSubMenu={this.state.showSubMenu}
                        menuIsOpen={this.state.menuIsOpen}
                        toogleMenuSmall={this.toogleMenuSmall.bind(this)} />}
                    />
                    
                    <Route exact path='/startups' render={props =>
                      <Startup
                        {...props}
                        handleCurrentPage={this.handleCurrentPage.bind(this)}
                        currentPage={this.state.currentPage}
                        showSubMenu={this.state.showSubMenu}
                        menuIsOpen={this.state.menuIsOpen}
                        toogleMenuSmall={this.toogleMenuSmall.bind(this)} />}
                    />
                    
                    <Route exact path='/contact' render={props =>
                      <Contact
                        {...props}
                        handleCurrentPage={this.handleCurrentPage.bind(this)}
                        currentPage={this.state.currentPage}
                        showSubMenu={this.state.showSubMenu}
                        menuIsOpen={this.state.menuIsOpen}
                        toogleMenuSmall={this.toogleMenuSmall.bind(this)} />}
                    />
                    
                    <Route exact path='/portfolio/:name' render={props =>
                      <PortfolioOpen
                        {...props}
                        handleCurrentPage={this.handleCurrentPage.bind(this)}
                        currentPage={this.state.currentPage}
                        showSubMenu={this.state.showSubMenu}
                        menuIsOpen={this.state.menuIsOpen}
                        toogleMenuSmall={this.toogleMenuSmall.bind(this)} />}
                    />

                    <Route render={() => <div>Not Found</div>} />

                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )} />
          </Router>

        </div>
      </div>
    )
  }
}

export default withRouter(App)
