import React from 'react'

import logoWork from '../assets/footer_brand_work.svg';
import logoAbout from '../assets/footer_brand_about.svg';
import logoServices from '../assets/footer_brand_services.svg';
import logoStartups from '../assets/footer_brand_startups.svg';
import logoContact from '../assets/footer_brand_contact.svg';

const Footer = props => {
  const getPageLogo = (page) => {
    switch (page) {
      case 'about': return logoAbout;
      case 'services': return logoServices;
      case 'startups': return logoStartups;
      case 'contact': return logoContact;
      default: return logoWork;
    }
  }

  return (
    <footer>
      {props.children}
      <span className='sep-1 m-y-30px' />
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-5'>
            <p className='text'>RioSlum Studio offers branding, design, messaging, strategy, and videography services to daring companies with bold vision. We build compelling brands that evoke emotion, demand attention, and attract business.</p>
            <p className='text'>
              <a href="./privacy-policy.html" target="_blank">Privacy Policy</a>
            </p>
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <p className='text'>402 Moylan Avenue<br/>Media, Pennsylvania 19063<br/>+1 843 516-5299</p>
          </div>
          <div className='col-xs-12 col-sm-6 col-md-2'>
            <ul className='links'>
              <li className='link'>
                  Follow Rio
              </li>
              <li className='link'>
                <a href='https://www.facebook.com/rioslumstudio/' target='_blank' rel='noopener noreferrer'>Facebook</a>
              </li>
              <li className='link'>
                <a href='https://vimeo.com/rioslumstudio' target='_blank' rel='noopener noreferrer'>Vimeo</a>
              </li>
              {/* <li className='link'>
                  <a href='#'>Twitter</a>
                </li>
                <li className='link'>
                  <a href='#'>Instagram</a>
                </li>
                <li className='link'>
                  <a href='#'>Youtube</a>
                </li> */}
            </ul>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-2'>
            <figure className='footer-logo'>
              <img src={getPageLogo(props.page)} alt='' />
            </figure>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
