// import React, { Component } from 'react'

// class SubMenu extends Component {

//   goTo (el) {
//     const headerFixed = document.querySelector('.header-fixed')
//     const top = document.getElementById(el).offsetTop - headerFixed.offsetHeight;

//     window.scroll({ top: top, behavior: 'smooth' });
//   }

//   render () {
//     const items = this.props.links.map((item, index) => {

//       return (
//         <li key={index} onClick={ () => this.goTo(item.slug) }>{item.name}</li>
//       )
//     })

//     return(
//       <div className='submenu clearfix'>
//         <div className='container'>
//           <ul className='clearfix'>
//             {items}
//           </ul>
//         </div>
//       </div>
//     )
//   }
// }

// export default SubMenu

import React from 'react'

const SubMenu = ({ links, showSubMenu }) => {
  const headerFixed = document.querySelector('.header-fixed')

  const items = links.map((item, index) => {
    const el = document.getElementById(item.slug)

    return (
      <li
        key={index}
        onClick={() => window.scroll({
          top: el.offsetTop - headerFixed.offsetHeight,
          behavior: 'smooth'
        })}
      >
        {item.name}
      </li>
    )
  })

  if (showSubMenu === undefined) {
    showSubMenu = false
  }

  return (
    <div className='submenu clearfix' style={{ top: (showSubMenu) ? '100%' : '-100%' }}>
      {/* <div className='submenu clearfix'> */}
      <div className='container'>
        <ul className='clearfix'>
          {items}
        </ul>
      </div>
    </div>
  )
}

export default SubMenu
