import React, { Component } from 'react'
import Nav from './Nav'
import { Link } from 'react-router-dom'

import logo from '../assets/header_brand.svg';

class Header extends Component {
  constructor (props) {
    super(props)

    this.state = {
      marginTop: 0
    }
  }

  componentDidMount () {
    const header = document.querySelector('.header')

    this.setState({
      marginTop: (this.props.currentPage === 'work') ? window.innerHeight - header.clientHeight : 0
    })
  }

  componentDidUpdate () {
    const header = document.querySelector('.header')
    const currentPage = this.props.currentPage
    let marginTop = 0

    if (currentPage === 'work') {
      marginTop = window.innerHeight - header.clientHeight

      if (this.state.marginTop !== marginTop) {
        this.handleMarginTop(marginTop)
      }
    } else {
      if (this.state.marginTop !== 0) {
        this.handleMarginTop(0)
      }
    }
  }

  handleMarginTop (marginTop) {
    this.setState({
      marginTop: marginTop
    })
  }

  render () {
    const headerClass = (this.props.className === 'work') ? 'header work' : 'header'

    return (
      <header className={headerClass} style={{ marginTop: this.state.marginTop }}>
        <div className='main-menu'>
          <div className='content'>
            <figure className='logo'>
              <Link to='/' ><img src={logo} alt='' /></Link>
            </figure>
            <Nav currentPage={this.props.currentPage} toogleMenuSmall={this.props.toogleMenuSmall} />
          </div>
        </div>
        {this.props.children}
      </header>
    )
  }
}

export default Header
